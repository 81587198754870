<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span class="content-box">
        <el-form label-width="80px" :inline="false" size="small">
          <el-row :gutter="rowSpan">
            <el-col :span="colSpan">
              <el-form-item label="房间号">
                <el-input
                  v-model="submitData.RoomNo"
                  placeholder="请输入房间号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="colSpan">
              <el-form-item label="房间名称">
                <el-input
                  v-model="submitData.RoomName"
                  placeholder="请输入房间号名称"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button type="primary" @click="saveRoom">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Room from "@/api/room.js";

export default {
  data() {
    return {
      title: "",
      submitData: {
        RoomGuid: "",
        RoomName: "",
        RoomNo: "",
      },
      showDialog: false,
      rowSpan: 20,
      colSpan: 11,
    };
  },

  mounted() {},

  methods: {
    // 保存房间
    async saveRoom() {
      if (this.submitData.RoomName == "") {
        this.$message.error("房间名称不能为空");
        return;
      } else if (this.submitData.RoomNo == "") {
        this.$message.error("房间号不能为空");
        return;
      }

      try {
        let { errcode } = await Room.saveRoom(this.submitData);
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.$emit("success");
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      }
    },

    resetPageData() {
      this.submitData = {
        RoomGuid: "",
        RoomName: "",
        RoomNo: "",
      };
    },

    // 打开对话框
    onShowDialog(event = {}) {
      let dataInfo = JSON.parse(JSON.stringify(event));
      this.title = dataInfo.RoomGuid ? "修改房间" : "添加房间";
      this.submitData = dataInfo;
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.resetPageData();
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
}
</style>
