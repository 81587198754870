<template>
  <div class="room">
    <div class="df-row">
      <div class="df-col">
        <el-button type="primary" size="small" @click="onShowAdd">添加房间</el-button>
      </div>
    </div>

    <el-table :data="roomList" border stripe>
      <el-table-column prop="RoomNo" label="房间号"> </el-table-column>
      <el-table-column prop="RoomName" label="房间名"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="onShowEdit(scope.row)"
            >修改</el-button
          >
          <el-button type="danger" size="small" @click="deleteRoom(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <AddRoomDialog ref="addRoomDialog" @success="getRoomList"></AddRoomDialog>
  </div>
</template>

<script>
import Room from "@/api/room.js";

import AddRoomDialog from "./components/add-room-dialog.vue";

export default {
  components: { AddRoomDialog },

  data() {
    return {
      roomList: [],
    };
  },

  mounted() {
    this.getRoomList();
  },

  methods: {
    // 获取房间列表
    async getRoomList() {
      try {
        let { data } = await Room.getRoomList();
        this.roomList = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 删除房间
    deleteRoom(event) {
      this.$confirm("删除后将无法恢复, 是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        try {
          let { errcode } = await Room.deleteRoom({
            room_guid: event.RoomGuid,
          });
          if (errcode === 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getRoomList();
          }
        } catch (err) {
          console.log(err);
        }
      });
    },

    // 打开添加对话框
    onShowAdd() {
      this.$refs.addRoomDialog.onShowDialog();
    },

    // 打开修改对话框
    onShowEdit(event){
        this.$refs.addRoomDialog.onShowDialog(event)
    }
  },
};
</script>

<style lang="less" scoped>
.room {
  padding-top: 20px;
}
</style>
