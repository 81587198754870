import { request } from "../utils/axios.js";

export default {
    // 设置 ==> 基础设置 ==> 房间管理
	getRoomList(params={}){
		return request('GET','/room/get_room_list', params);
	},

	saveRoom(params={}){
		return request('POST','/room/save_room', params);
	},

    deleteRoom(params={}){
		return request('GET','/room/delete_room', params);
	},
}